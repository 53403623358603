.auth-container {
    .form-col {
      padding: 40px 100px;
  
      .login-card {
        width: 360px;
        padding: 12px;
        border-radius: 12px;
  
        header {
          display: flex;
  
          .decoration {
            background-color: #3e7abf;
            width: 6px;
            min-width: 6px;
            margin: 0.25rem 0;
          }
  
          .header-container {
            padding-left: 0.5rem;
  
            .title {
              font-size: 1.625rem;
              font-weight: bold;
              line-height: 1.625rem;
              margin-bottom: 0.75rem;
            }
  
            .subtitle {
              font-size: 0.8125rem;
              line-height: 0.8125rem;
            }
          }
        }
  
        .form {
          margin-top: 32px;
  
          > div:not(:last-child) {
            margin-bottom: 24px;
          }
  
          span {
            font-size: 0.8125rem;
            line-height: 0.8125rem;
          }
  
          button > span {
            font-size: inherit;
            line-height: inherit;
          }
  
          .keep-connected .ant-form-item {
            margin-bottom: 0;
          }
  
          .custom-link {
            font-weight: bold;
            text-decoration: underline;
            color: var(--default-text-color);
  
            font-size: 0.8125rem;
            line-height: 0.8125rem;
          }
        }
  
        .error-alert {
          margin-bottom: 0;
        }
      }
    }
  
    @media (max-width: 1177px) {
      flex-direction: column;
      align-items: stretch;
      height: auto;
  
      .ant-col {
        width: 100%;
        max-width: 100%;
        flex: 0 0 auto;
      }
  
      .form-col {
        padding: 12px;
  
        .login-card {
          width: 100%;
          max-width: 360px;
        }
      }
    }
  
    @media (max-width: 375px) {
      .form-col {
        .login-card {
          padding: 0;
        }
      }
    }
  }
  