.avatar_dropdown-container {
  align-items: center;

  .tool_tip {
    display: none;
  }

  @media (max-width: 800px) {
    .avatar_username {
      display: block;
    }
    .tool_tip {
      display: inline-block;
    }
    .default_img {
      display: block;
    }
  }
}
