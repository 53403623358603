.hero-container {
  padding: 60px;
  min-height: 100vh;

  background-image: url('../../assets/images/logmetrics-background.png');

  background-image: url('../../assets/images/logmetrics-background.webp');

  background-image: image-set(
    '../../assets/images/logmetrics-background.png'
  );

  background-image: image-set(
    '../../assets/images/logmetrics-background.webp'
  );

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1024px) {
    padding: 40px 20px;
  }
}
