:root {
  --default-text-color: #363a45;
  --default-background-color: #212529;
  --default-box-shadow: 0px 3px 6px #00000029;
}

body {
  @font-face {
    font-family: 'Roboto';
    src: url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')
      format('truetype');
  }
  font-family: 'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji';

  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .ant-form-item-explain-error,
  .ant-form-item-explain-warning {
    max-width: fit-content;
  }

  .error-alert {
    overflow: auto;
    margin: 24px 0px;
    border-radius: 5px;

    .ant-alert-message {
      font-size: 15px;
    }
    .ant-alert-description {
      font-size: 13px;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-loading-icon {
  margin-right: 10px;
}

.ant-breadcrumb {
  a:hover {
    color: #ffd354 !important;
    filter: contrast(1.1) brightness(0.9);
  }
}
